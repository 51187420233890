 
.hover-img {
    -webkit-filter: blur(4px);
    filter: blur(4px);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    display: block;
    margin: 9% 0% 80% 60%;
    width: 30%;
    left: 80%;
    border-radius: 100%;
    }
.hover-img:hover {
    animation-delay: 0.5s;
    -webkit-filter: blur(0);
    filter: blur(0);

}
