$primary-color: #aad449;

@import 'animate.css';

@import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,700&family=Handjet:wght@300&family=Montserrat:wght@500;700&family=Source+Code+Pro:wght@500&display=swap');

@import '~loaders.css/src/animations/ball-scale-multiple.scss';


@font-face {
  font-family: 'Helvetica Neue';
  src: url(./assets/fonts/helvetica-neu.ttf) format('ttf');
}


@font-face {
  font-family: 'La Belle Aurore Neue';
  src: url(./assets/fonts/LaBelleAurore.woff2) format('woff2');
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url(./assets/fonts/CoolveticaRg-Regular.woff2) format('woff2');
}

input, textarea{
  font-family: 'Coolvetica';
  src: url('/assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}

@media (max-width: 767px) {
  .layout {
    flex-direction: column;
  }

  .sidebar {
    order: -1;
  }
}