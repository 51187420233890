.nav-bar {
    background: #000000;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    min-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:2rem;


}

.logo {
    display: inline-block;
    padding: 8px;

    img {
        display: block;
        margin: 8px auto;
        width: 50px;
        height: auto;

        &.sub-logo {
            width: 40px;
        }
    }
}

nav {
    display: inline-block;
    height: 100%;



    a {
        font-size: 22px;
        color: #4d4d4e;
        display: inline-block;
        line-height: 100px;
        height: 60px;
        position: relative;
        text-decoration: none;

        i {
            transition: all 0.3s ease-out;
        }

        &:hover {
            color: #ffd700;

            svg {
                opacity: 0;
            }

            &:after {
                opacity: 1;
            }
        }

        &::after {
            content: '';
            font-size: 9px;
            letter-spacing: 2px;
            position: absolute;
            bottom: 25px;
            top: 10px;
            display: block;
            width: 100%;
            text-align: center;
            opacity: 0;
            transition: all 0.3s ease-out;

        }

        &:first-child {
            &::after {
                content: 'HOME';
            }
        }
    }

    a.about-link {
        &:after {
            content: 'ABOUT';
        }
    }

    a.contact-link {
        &:after {
            content: 'CONTACT';
        }
    }
    a.Linked-link {
        &:after {
            content: 'LINKEDIN';
        }
    }
    a.telegram-link {
        &:after {
            content: 'TELEGRAM';
        }
    }
    a.github-link {
        &:after {
            content: 'GITHUB';
        }
    }
    a.slack-link {
        &:after {
            content: 'DISCORD';
        }
    }

    a.active {
        svg {
            color: #ffd700;
        }
    }
}

ul {
    display: inline-block;
    list-style: none;
    padding: 10;
    margin-left: 10;
    text-align: center;
    justify-items: center;
}

li {

    display: inline-block;
    margin-left: 120px;
    margin-right: auto;
    padding-left: 30;

    a {
        padding: 7px;
        display: inline-block;
        font-size: 15px;
        line-height: 16px;

        &:hover svg {
            color: #ffd700;
        }
    }
}



